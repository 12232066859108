<template>
    <div class="header-menu">
        <ycc-dropdown class="header-item"
                      :unique-key="id">
            <div class="header-menu_button sgc-dropdown-toggle"
                 title="More">
                <span class="icon-ycc">
                    <i class="material-icons">more_vert</i>
                </span>
            </div>

            <ul class="sgc-dropdown-menu menu-ycc rtl">
                <li class="sgc-dropdown-menu-item">
                    <router-link class="btn-link-ycc btn-ycc-link menu-item"
                                 :to="{name: PAGES.PROFILE}">
                        <span class="icon-ycc">
                            <i class="material-icons">account_circle</i>
                        </span>
                        <span v-translate>Profile</span>
                    </router-link>
                </li>

                <li class="sgc-dropdown-menu-item"
                    v-if="isAdmin">
                    <router-link class="btn-link-ycc btn-ycc-link menu-item"
                                 :to="{name: PAGES.ADMIN}">
                        <span class="icon-ycc">
                            <i class="material-icons">assignment</i>
                        </span>
                        <span v-translate>Administration</span>
                    </router-link>
                </li>

                <li class="sgc-dropdown-menu-item sgc-dropdown-not_closing"
                    v-if="!isEnterprise">
                    <a class="btn-link-ycc btn-ycc-link menu-item"
                       :href="getPricesLink('yucca-header-menu', $language.current)"
                       target="_blank"
                       rel="noopener noreferer">
                            <span class="icon-ycc">
                                <i class="material-icons">trending_up</i>
                            </span>
                        <span v-translate>Upgrade</span>
                    </a>
                </li>

                <li class="sgc-dropdown-menu-item sgc-dropdown-not_closing"
                    v-if="documentationLink">
                    <a class="btn-link-ycc btn-ycc-link menu-item row-ycc-center"
                       :href="documentationLink"
                       target="_blank"
                       rel="noopener noreferer">
                            <span class="icon-ycc">
                                <i class="material-icons">help</i>
                            </span>
                        <span class="margin-right-5"
                              v-translate>Documentation</span>
                        <span class="material-icons open-in-new">open_in_new</span>
                    </a>
                </li>

                <li class="sgc-dropdown-menu-item">
                    <a href="#"
                       class="btn-link-ycc btn-ycc-link menu-item"
                       @click.prevent="logout">
                            <span class="icon-ycc">
                                <i class="material-icons">exit_to_app</i>
                            </span>
                        <span v-translate>Logout</span>
                    </a>
                </li>
            </ul>
        </ycc-dropdown>
    </div>
</template>

<script>
import PAGES             from '@/constants/pages';
import { getPricesLink } from '@/constants/links';
import YccDropdown       from '@/components/ycc-dropdown/YccDropdown.vue';

export default {
    name      : 'YccHeaderMenu',
    components: { YccDropdown },
    props     : {
        version          : String,
        documentationLink: String,
        isAdmin          : Boolean,
        id               : { type: String, default: 'headerMenu' },
    },
    data () {
        return {
            PAGES,
            isEnterprise: this.$yccConfig.isEnterprise,
        };
    },
    methods : {
        getPricesLink,
        logout () {
            this.$emit('logout');
        },
    },
};
</script>

<style lang="scss"
       scoped>
.sgc-dropdown-menu {
    width : 215px;
}
</style>
