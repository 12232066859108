import axios                        from 'axios';
import {
    ADDING_STREAM_URL,
    DELETE_STREAM_URL,
    PAUSE_STREAM_URL,
    PREVIEW_STREAM_URL,
    PROBE_STREAM_URL,
    RESUME_STREAM_URL,
    UPDATING_STREAM_URL,
    getStreamPermissionsUrl,
    getStreamShareUrl,
    getStreamLogsUrl,
    getStreamInfoUrl,
}                                   from '@/constants/api';
// eslint-disable-next-line import/no-cycle
import httpClient                   from '@/config/httpClient';
import { STREAM_SOURCE_ERR }        from '@/constants/errorTypes';
import { STREAM_INFO_SAVED_NOTICE } from '@/constants/noticeTypes';

const { CancelToken } = axios;

/* eslint-disable no-param-reassign */
export default {
    namespaced: true,
    state     : {
        // Add stream
        isSendingStream: false,

        fetchingStreamStatusTimer: null,
        cancelStreamInfoRequest  : null,

        fetchingLogsTimer: null,
        cancelLogsRequest: null,

        // Info stream
        // Maps of Stream States
        deletingStateMap: {},
        changingStateMap: {},

        // StreamPage
        currentStream          : null,
        isFetchingRunningStream: false,
        isFetchingStreamInfo   : false,
        isDeletedStream        : false,
        isFetchingLogs         : false,

        currentStreamPermissions: [],
        currentStreamLogs       : null,

        isAddingPermission         : false,
        isFetchingStreamPermissions: false,
    },
    mutations : {
        // Add stream
        // Info Stream
        // StreamPage
        setStreamStatus (state, payload) {
            state.currentStream.status = payload;
        },
        setFailureReason (state, payload) {
            state.currentStream.failureReason = payload;
        },
        setStream (state, { stream }) {
            state.currentStream = { ...state.currentStream, ...stream };
        },
        deleteCurrentStream (state) {
            state.currentStream            = null;
            state.currentStreamPermissions = null;
            state.currentStreamLogs        = null;
        },
        setDeletingStreamState (state, { streamUid, streamState }) {
            const deletingStateMap      = JSON.parse(JSON.stringify(state.deletingStateMap));
            deletingStateMap[streamUid] = streamState;
            state.deletingStateMap      = deletingStateMap;
        },
        setChangingStreamState (state, { streamUid, streamState }) {
            const changingStateMap      = JSON.parse(JSON.stringify(state.changingStateMap));
            changingStateMap[streamUid] = streamState;
            state.changingStateMap      = changingStateMap;
        },
        setDeletedStreamState (state, { isDeletedStream }) {
            state.isDeletedStream = isDeletedStream;
        },
        setCurrentStreamPermissions (state, { permissions }) {
            state.currentStreamPermissions = permissions;
        },
        setCancelStreamInfoRequest (state, { cancel }) {
            state.cancelStreamInfoRequest = cancel;
        },
        setFetchingStatusTimer (state, { timer }) {
            state.fetchingStreamStatusTimer = timer;
        },
        clearFetchingStreamStatusTimer (state) {
            clearTimeout(state.fetchingStreamStatusTimer);
        },
        setLogs (state, { logs }) {
            state.currentStreamLogs = logs;
        },
        setFetchingLogsTimer (state, { timer }) {
            state.fetchingLogsTimer = timer;
        },
        clearFetchingLogsTimer (state) {
            clearTimeout(state.fetchingLogsTimer);
        },
        setCancelLogsRequest (state, { cancel }) {
            state.cancelLogsRequest = cancel;
        },
    },
    getters   : {
        isArchiveEnable: (state) => !!state.currentStream?.archive?.isEnable,
        isLiveOnDemand : (state) => state.currentStream?.live.onDemand,
    },
    actions   : {
        // Add stream
        async sendStream ({ commit, state }, payload) {
            let uid               = null;
            state.isSendingStream = true;

            await httpClient
                .post(ADDING_STREAM_URL, payload, { timeout: 35000 })
                .then((res) => {
                    commit('deleteError', { name: STREAM_SOURCE_ERR }, { root: true });
                    // eslint-disable-next-line prefer-destructuring
                    uid = res.data.uid;
                })
                .finally(() => {
                    state.isSendingStream = false;
                });

            return uid;
        },
        // Info stream
        pauseStream ({ commit }, { streamUid }) {
            commit('setChangingStreamState', { streamUid, streamState: true });

            httpClient
                .post(`${PAUSE_STREAM_URL}/${streamUid}`)
                .then((res) => {
                    commit('setStreamStatus', res.data.status);
                })
                .finally(() => {
                    commit('setChangingStreamState', { streamUid, streamState: false });
                });
        },
        resumeStream ({ commit }, { streamUid }) {
            commit('setChangingStreamState', { streamUid, streamState: true });

            httpClient
                .post(`${RESUME_STREAM_URL}/${streamUid}`)
                .then((res) => {
                    commit('setStreamStatus', res.data.status);
                })
                .finally(() => {
                    commit('setChangingStreamState', { streamUid, streamState: false });
                });
        },
        deleteStream ({ commit }, { streamUid }) {
            commit('setDeletingStreamState', { streamUid, streamState: true });

            httpClient
                .delete(`${DELETE_STREAM_URL}/${streamUid}`, {
                    params: { purge: true },
                })
                .then(() => {
                    commit('setDeletedStreamState', { isDeletedStream: true });
                })
                .finally(() => {
                    commit('setDeletingStreamState', { streamUid, streamState: false });
                });
        },
        saveStream ({ commit, state, dispatch }, stream) {
            state.isSendingStream = true;

            httpClient
                .put(`${UPDATING_STREAM_URL}/${stream.uid}`, stream)
                .then((res) => {
                    commit('setStream', { stream: res.data });
                    dispatch('updateStreamInStreams', { stream: res.data });
                    commit('setNotice', { name: STREAM_INFO_SAVED_NOTICE }, { root: true });
                })
                .finally(() => {
                    state.isSendingStream = false;
                });
        },
        updateStreamInStreams ({ commit, rootState }, { stream }) {
            const streams = rootState.streams.map((streamItem) => {
                if (streamItem.uid === stream.uid) {
                    Object.keys(stream).forEach((key) => {
                        streamItem[key] = stream[key];
                    });
                }
                return streamItem;
            });
            commit('setStreams', { streams }, { root: true });
        },
        async probeStream (store, { streamUrl, streamId }) {
            return axios
                .get(PROBE_STREAM_URL, { params: { url: streamUrl, stream_id: streamId }, timeout: 60000 })
                .then((res) => res.data);
        },
        async previewStream (store, { streamUrl, streamId }) {
            return axios
                .get(PREVIEW_STREAM_URL, { params: { url: streamUrl, stream_id: streamId }, timeout: 60000 })
                .then((res) => res.data);
        },
        // Info stream, StreamPage
        runUpdatingStreamStatus ({ commit, dispatch }, { streamUid, isPlayer }) {
            dispatch('fetchStreamStatus', { streamUid, isPlayer });

            let timer = setTimeout(function tick () {
                dispatch('fetchStreamStatus', { streamUid, isPlayer });

                timer = setTimeout(tick, 1000);
                commit('setFetchingStatusTimer', { timer });
            }, 1000);

            commit('setFetchingStatusTimer', { timer });
        },
        clearStreamTimers ({ commit, state }) {
            commit('clearFetchingStreamStatusTimer');
            state.cancelStreamInfoRequest('replaced');
        },
        shareStream (store, { streamUid }) {
            return httpClient
                .get(getStreamShareUrl(streamUid))
                .then((res) => res.data);
        },
        denyStreamPublicAccess (store, { streamUid }) {
            return httpClient
                .delete(getStreamShareUrl(streamUid))
                .then((res) => res.data);
        },

        // StreamPage
        fetchStream ({ commit, state, getters }, { streamUid, isPlayer }) {
            state.isFetchingRunningStream = true;

            httpClient
                .get(getStreamInfoUrl(streamUid, getters.isLiveOnDemand && isPlayer))
                .then((res) => {
                    commit('setStream', { stream: res.data });
                })
                .finally(() => {
                    state.isFetchingRunningStream = false;
                });
        },
        fetchStreamStatus ({ commit, state, getters }, { streamUid, isPlayer }) {
            if (state.cancelStreamInfoRequest) {
                state.cancelStreamInfoRequest('replaced');
            }

            if (state.currentStream) {
                httpClient
                    .get(getStreamInfoUrl(streamUid, getters.isLiveOnDemand && isPlayer), {
                        cancelToken: new CancelToken((cancel) => {
                            commit('setCancelStreamInfoRequest', { cancel });
                        }),
                    })
                    .then((res) => {
                        commit('setStreamStatus', res.data.status);
                        commit('setFailureReason', res.data.failureReason);
                    });
            }
        },
        fetchStreamInfo ({ commit, state, getters }, { streamUid, isPlayer }) {
            state.isFetchingStreamInfo = true;

            if (state.cancelStreamInfoRequest) {
                state.cancelStreamInfoRequest('replaced');
            }

            httpClient
                .get(getStreamInfoUrl(streamUid, getters.isLiveOnDemand && isPlayer), {
                    cancelToken: new CancelToken((cancel) => {
                        commit('setCancelStreamInfoRequest', { cancel });
                    }),
                })
                .then((res) => {
                    commit('setStream', { stream: res.data });
                    state.isFetchingStreamInfo = false;
                })
                .catch(() => {
                    state.isFetchingStreamInfo = false;
                });
        },
        fetchLogs ({ state, commit }, { uid }) {
            if (state.cancelLogsRequest) {
                state.cancelLogsRequest('replaced');
            }

            httpClient
                .get(getStreamLogsUrl(uid), {
                    cancelToken: new CancelToken((cancel) => {
                        commit('setCancelLogsRequest', { cancel });
                    }),
                })
                .then((res) => {
                    commit('setLogs', { logs: res.data });
                })
                .finally(() => {
                    state.isFetchingLogs = false;
                });
        },
        runUpdatingLogs ({ state, commit, dispatch }, { uid }) {
            state.isFetchingLogs = true;

            dispatch('fetchLogs', { uid });

            let timer = setTimeout(function tick () {
                dispatch('fetchLogs', { uid });

                timer = setTimeout(tick, 1000);
                commit('setFetchingLogsTimer', { timer });
            }, 1000);

            commit('setFetchingLogsTimer', { timer });
        },
        clearLogsTimer ({ commit, state }) {
            commit('clearFetchingLogsTimer');

            if (state.cancelLogsRequest) {
                state.cancelLogsRequest('replaced');
            }
        },
        updateCurrentStream ({ dispatch }, { streamUid, isPlayer }) {
            dispatch('fetchStream', { streamUid, isPlayer });
        },
        clearCurrentStream ({ commit }) {
            commit('deleteCurrentStream');
        },
        fetchStreamPermissions ({ commit, state }, { streamUid }) {
            state.isFetchingStreamPermissions = true;

            httpClient
                .get(getStreamPermissionsUrl(streamUid))
                .then((res) => {
                    commit('setCurrentStreamPermissions', { permissions: res.data });
                })
                .finally(() => {
                    state.isFetchingStreamPermissions = false;
                });
        },
        updatePermissions ({ commit, state }, { permissions, streamUid }) {
            state.isAddingPermission = true;

            httpClient
                .post(getStreamPermissionsUrl(streamUid), permissions)
                .then((res) => {
                    commit('setCurrentStreamPermissions', { permissions: res.data });
                })
                .finally(() => {
                    state.isAddingPermission = false;
                });
        },
    },
};
