/* eslint-disable no-param-reassign */
export default {
    setPeriod (state, { period }) {
        state.period = period;
    },
    setPlaylist (state, { playlist }) {
        state.playlist = playlist;
    },
    setProgressBarWidth (state, { progressBarWidth }) {
        state.progressBarWidth = progressBarWidth;
    },
    setRanges (state, { ranges }) {
        state.ranges = ranges;
    },
    setPlayingTime (state, { time }) {
        state.playingTime = time;
    },
    setPlayerViewMode (state, { mode }) {
        state.playerViewMode = mode;
    },
    setFetching (state, { isFetchingArchive }) {
        state.isFetchingArchive = isFetchingArchive;
    },
    setAvailableDays (state, { availableDays }) {
        state.availableDays = availableDays;
    },
    setFetchingArchiveRanges (state, { isFetchingArchiveRanges }) {
        state.isFetchingArchiveRanges = isFetchingArchiveRanges;
    },
    setArchiveStats (state, { stats }) {
        state.archiveStats = stats;
    },
    setArchiveDataWatcherTimer (state, { timer }) {
        state.archiveDataWatcherTimer = timer;
    },
    setDownloadBlockVisibility (state, { isVisibleDownloadBlock }) {
        state.isVisibleDownloadBlock = isVisibleDownloadBlock;
    },
    setDownloadLimits (state, { downloadLimitsPX }) {
        state.downloadLimitsPX = downloadLimitsPX;
    },
    setArchiveDownloadMaxSeconds (state, { archiveDownloadMaxSeconds }) {
        state.archiveDownloadMaxSeconds = archiveDownloadMaxSeconds;
    },
    setAnnotations (state, { annotations }) {
        state.annotations = annotations;
    },
    setFetchingAnnotations (state, { isFetchingAnnotations }) {
        state.isFetchingAnnotations = isFetchingAnnotations;
    },
    setSpeedIndex (state, { speedIndex }) {
        state.speedIndex = speedIndex;
    },
    setThumbPos (state, { thumbPos }) {
        state.thumbPos = thumbPos;
    },
    setPlayingState (state, { isPlaying }) {
        state.isPlaying = isPlaying;
    },
    setLoadingState (state, { isLoading }) {
        state.isLoading = isLoading;
    },
};
