<template>
    <div id="app">
        <ycc-layout :is-fix-content="isFixContent"
                    :is-read-only-profile="isReadOnlyProfile"
                    :is-close-aside-outside="isFixContent"
                    :is-auth-or-init-page="isAuthOrInitPage"
                    :is-license-expired="isLicenseExpired"
                    :is-empty-route="isEmptyRoute"
                    :warnings="warnings"
                    @logout="logout">
            <template slot="content">
                <router-view />
            </template>
        </ycc-layout>

        <vue-progress-bar v-show="!isAuthOrInitPage">
        </vue-progress-bar>

        <datepicker>
        </datepicker>
    </div>
</template>

<script>
import Vue               from 'vue';
import {
    mapState,
    mapGetters,
}                        from 'vuex';
/* eslint-disable-next-line */
import { Notification }  from 'element-ui';
import dayjs             from 'dayjs';
import throttle          from 'lodash.throttle';
import 'dayjs/locale/ru';
import { getPricesLink } from '@/constants/links';
import YccLayout         from '@/components/ycc-layout/YccLayout.vue';
import {
    ACCESS_DENIED_ERR,
    SERVER_ERROR_CODES,
    SERVER_ERR,
    SERVER_NOT_RESPONSE_ERR,
    SERVER_UNKNOWN_ERR,
}                        from './constants/errorTypes';
import PAGES             from './constants/pages';
import pageNames         from './constants/pageNames';
import {
    PROFILE_UPDATED_NOTICE,
    USER_BLOCKED_NOTICE,
    USER_SAVED_NOTICE,
    USER_UNBLOCKED_NOTICE,
    STREAM_INFO_SAVED_NOTICE,
}                        from './constants/noticeTypes';
import { LANG_MAP }      from './constants/main';

export default {
    name      : 'app',
    components: { YccLayout },
    data () {
        return {
            isAuthOrInitPage : true,
            noticeElems      : {},
            serverErrorNotice: null,
        };
    },
    computed: {
        ...mapState({
            stateErrors   : (state) => state.errors,
            notice        : (state) => state.notice,
            version       : (state) => state.version,
            isFixContent  : (state) => state.isFixContent,
            isOpenAside   : (state) => state.isOpenAside,
            license       : (state) => state.license.license,
            healthWarnings: (state) => state.healthWarnings,
        }),
        ...mapGetters({
            userLang         : 'profile/userLang',
            isReadOnlyProfile: 'profile/isReadOnlyProfile',
        }),
        PAGE_NAMES () {
            return pageNames(this.$gettext);
        },
        noticeMessages () {
            return {
                [PROFILE_UPDATED_NOTICE]  : this.$gettext('Profile successfully updated'),
                [USER_SAVED_NOTICE]       : this.$gettext('User successfully saved'),
                [USER_BLOCKED_NOTICE]     : this.$gettext('User access successfully blocked'),
                [USER_UNBLOCKED_NOTICE]   : this.$gettext('User access successfully opened'),
                [STREAM_INFO_SAVED_NOTICE]: this.$gettext('Stream information successfully saved'),
            };
        },
        errorMessages () {
            return {
                [ACCESS_DENIED_ERR]                                       :
                    this.$gettext('Access denied'),
                [SERVER_UNKNOWN_ERR]                                      :
                    this.$gettext('Something went wrong'),
                [SERVER_NOT_RESPONSE_ERR]                                 :
                    this.$gettext('Server is not responding'),
                [SERVER_ERROR_CODES.AlreadyUsed]                          :
                    this.$gettext('Already used'),
                [SERVER_ERROR_CODES.AuthorizationFailed]                  :
                    this.$gettext('Oops... Incorrect login or password'),
                [SERVER_ERROR_CODES.EnterpriseOnly]                       :
                    this.$gettext('Enterprise only'),
                [SERVER_ERROR_CODES.Forbidden]                            :
                    this.$gettext('Forbidden'),
                [SERVER_ERROR_CODES.InternalServerError]                  :
                    this.$gettext('Internal server error'),
                [SERVER_ERROR_CODES.InvalidOldPassword]                   :
                    this.$gettext('Invalid old password'),
                [SERVER_ERROR_CODES.NotFound]                             :
                    this.$gettext('Not found'),
                [SERVER_ERROR_CODES.OldPasswordAndNewPasswordCannotBeSame]:
                    this.$gettext('Old password and new password cannot be same'),
                [SERVER_ERROR_CODES.PasswordIsNotComplexEnough]           :
                    this.$gettext('Password is not complex enough'),
                [SERVER_ERROR_CODES.RequestDecodeError]                   :
                    this.$gettext('Request decode error'),
                [SERVER_ERROR_CODES.ResponseEncodeError]                  :
                    this.$gettext('Response encode error'),
                [SERVER_ERROR_CODES.TimeRangeIsTooLong]                   :
                    this.$gettext('Time range is too long'),
                [SERVER_ERROR_CODES.Unavailable]                          :
                    this.$gettext('Unavailable'),
                [SERVER_ERROR_CODES.ValidationError]                      :
                    this.$gettext('Validation error'),
                [SERVER_ERROR_CODES.LicenseExpired]                       :
                    this.$gettext('License is missing or expired'),
                [SERVER_ERROR_CODES.QuotaReached]                         :
                    this.$gettext('Quota is reached'),
                [SERVER_ERROR_CODES.IncorrectLicense]                     :
                    this.$gettext('License is incorrect '),
            };
        },
        warnings () {
            const warningsArr = [];

            this.healthWarnings.forEach((warning) => {
                if (warning.code === 'LICENSE_EXPIRATION') {
                    // eslint-disable-next-line
                    const uploadLicenseLink = `<a class="margin-right-15 btn-ycc-link btn-ycc-link-info" href="/ui/license">${this.$gettext('Upload a license.')}</a>`;
                    // eslint-disable-next-line
                    const buyLicenseLink    = `<a class="btn-ycc-link btn-ycc-link-info" href="${this.buyYuccaUrl}" target="_blank" rel="noopener noreferer">${this.$gettext('Get a license.')}</a>`;
                    const warningText       = this.$gettextInterpolate(
                        // eslint-disable-next-line
                        this.$gettext('License will expire at %{day}. Addition streams and watching archive will be disabled. Upload a new license to ensure uninterrupted service. If you do not have a license you can buy\u00A0it.'),
                        { day: this.$formatDate(warning.expiresAt, 'D MMM YYYY') },
                    );

                    warningsArr.push({
                        code   : warning.code,
                        message: `${warningText} <br /> ${uploadLicenseLink} ${buyLicenseLink}`,
                    });
                }

                if (warning.code === 'ALLOC_DIR_DISK_SPACE') {
                    warningsArr.push({
                        code   : warning.code,
                        message: this.$gettextInterpolate(
                            // eslint-disable-next-line
                            this.$gettext('The archive directory is running out of memory, more than %{usedPercent}\u0025 used.'),
                            { usedPercent: Math.floor(warning.usedPercent) },
                        ),
                    });
                }
            });

            return warningsArr;
        },
        buyYuccaUrl () {
            return getPricesLink('yucca-license-stopper', this.$language.current);
        },
        isEmptyRoute () {
            return this.$route.name === null;
        },
        isLicenseExpired () {
            if (this.license) {
                return this.license.expired;
            }
            return this.$yccConfig.isLicenseExpired;
        },
    },
    created () {
        this.setConfig();
        this.initApp();
    },
    mounted () {
        this.$Progress.finish();
    },
    watch  : {
        userLang (newLang) {
            this.changeLang(newLang);
        },
        stateErrors (newErrors, oldErrors) {
            if (newErrors[SERVER_ERR] && newErrors[SERVER_ERR] === oldErrors[SERVER_ERR] && this.serverErrorNotice) {
                return;
            }
            if (newErrors[SERVER_ERR]) {
                this.serverErrorNotice = Notification({
                    title   : this.$gettext('Error'),
                    message : this.errorMessages[newErrors[SERVER_ERR]] || this.errorMessages[SERVER_UNKNOWN_ERR],
                    type    : 'error',
                    duration: 4500,
                });
                setTimeout(() => {
                    this.$store.dispatch('deleteError', { errorName: SERVER_ERR });
                }, 4500);
            }
            else if (this.serverErrorNotice) {
                this.serverErrorNotice.close();
                this.serverErrorNotice = null;
            }
        },
        notice (newValue) {
            if (newValue) {
                this.noticeElems[newValue.name] = Notification({
                    message : this.noticeMessages[newValue.name] || newValue.message,
                    title   : this.$gettext('Success'),
                    type    : 'success',
                    duration: 4500,
                });
                this.$store.dispatch('deleteNotice', { name: newValue.name });
                setTimeout(() => {
                    if (this.noticeElems[newValue.name]) {
                        this.noticeElems[newValue.name].close();
                        this.noticeElems[newValue.name] = null;
                    }
                }, 4500);
            }
        },
    },
    methods: {
        setConfig () {
            this.$setDocumentTitle();
            this.keepConfigToStore();
        },
        initApp () {
            this.runPlugins();
            this.setRouterHooks();
            this.$store.dispatch('getStreamsPageSettingFromLocalStorage');
            this.setEventHandlers();
        },
        runPlugins () {
            this.$Progress.start();
        },
        setRouterHooks () {
            this.$router.beforeEach((to, from, next) => {
                this.$setDocumentTitle([this.PAGE_NAMES[to.name]]);

                if (to.meta.progress !== undefined) {
                    this.$Progress.parseMeta(to.meta.progress);
                }

                this.$Progress.start();
                next();
            });

            this.$router.afterEach((to) => {
                this.$Progress.finish();

                this.isAuthOrInitPage = to.name === PAGES.AUTH || to.name === PAGES.INIT;

                if (!this.isAuthOrInitPage) {
                    this.fetchData();
                }

                if (to.name === PAGES.STREAM_VIEW
                    || to.name === PAGES.STREAM
                    || to.name === PAGES.STREAM_INFO
                    || to.name === PAGES.STREAM_SETTING
                    || to.name === PAGES.STREAM_PERMISSION) {
                    this.$setDocumentTitle([to.params.name]);
                }
                else {
                    this.$setDocumentTitle([this.PAGE_NAMES[to.name]]);
                }
            });
        },
        setEventHandlers () {
            window.addEventListener('resize', throttle(() => {
                this.$store.dispatch('resizeApp');
            }, 100));
        },
        fetchData () {
            this.$store.dispatch('fetchHealth');
            this.$store.dispatch('profile/fetchUser');
        },
        changeLang (lang) {
            this.$language.current = LANG_MAP[lang] || this.$yccConfig.language;
            dayjs.locale(this.$language.current);
            Vue.$setLanguage(this.$language.current);
        },
        logout () {
            this.$store.dispatch('logout');
            this.changeLang(this.$yccConfig.defaultLanguage);
        },
        keepConfigToStore () {
            this.$store.commit('player/setArchiveDownloadMaxSeconds', {
                archiveDownloadMaxSeconds: this.$yccConfig.archiveDownloadMaxSeconds,
            });
        },
    },
};
</script>

<style lang="scss"
       scoped>
@import "./scss/pages/loading-page";
</style>
