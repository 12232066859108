/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
/* eslint-disable import/no-cycle */
import dayjs           from 'dayjs';
import axios           from 'axios';
import {
    DEFAULT_PLAYLIST_DURATION,
    PLAYER_VIEW_MODES,
}                      from '@/constants/main';
import {
    getArchiveStatsUrl,
    getAvailableDaysUrl,
    getRangesUrl,
    ANNOTATIONS_URL,
    getDownloadArchiveUrl,
}                      from '@/constants/api';
import httpClient      from '@/config/httpClient';
import {
    convertDateToSeconds,
    formatDate,
}                      from '@/extends/lib';
import { RANGE_TYPES } from '@/components/ycc-player/constants';
import {
    getDefaultStart,
    getDefaultEnd,
    getDefaultPeriod,
    getDefaultDownloadLimitsPX,
    getDefaultPlaylist,
}                      from './lib';
import mutations       from './mutations';

const { CancelToken } = axios;
const ZOOM_INDEX      = 1.5;
const HOUR_IN_MS      = 60 * 60 * 1000;
const MIN_DURATION    = 5 * 60 * 1000;
const MAX_DURATION    = 24 * HOUR_IN_MS;

export default {
    namespaced: true,
    mutations,
    state     : {
        archiveStats : null,
        availableDays: [],

        /* progress-bar period */
        period: getDefaultPeriod(),
        /* progress-bar ranges */
        ranges          : [],
        annotations     : [],
        progressBarWidth: 0,
        /* live/archive */
        playerViewMode: PLAYER_VIEW_MODES.LIVE,
        playlist      : null,
        playingTime   : dayjs(new Date()).valueOf(),
        thumbPos      : 0,
        speedIndex    : 1,

        archiveDownloadMaxSeconds: 3600,
        downloadLimitsPX         : getDefaultDownloadLimitsPX(),

        isVisibleDownloadBlock : false,
        isFetchingArchive      : false,
        isFetchingArchiveRanges: false,
        isFetchingAnnotations  : false,
        isPlaying              : false,
        isLoading              : false,

        cancelArchiveRangesRequest: null,
        archiveDataWatcherTimer   : null,
    },
    getters   : {
        duration (state) {
            return state.period.end - state.period.start;
        },
        secondsInPx              : (state, getters) => getters.duration / (state.progressBarWidth * 1000),
        periodISO                : (state) => ({
            start: dayjs(state.period.start).toISOString(),
            end  : dayjs(state.period.end).toISOString(),
        }),
        periodModifier           : (state, getters) => ((getters.duration - (getters.duration / ZOOM_INDEX)) / 2),
        getStartModifier         : (state, getters) => (playingTime) => (
            ((playingTime - state.period.start) / getters.duration) * (getters.periodModifier * 2)
        ),
        getEndModifier           : (state, getters) => (playingTime) => (
            ((state.period.end - playingTime) / getters.duration) * (getters.periodModifier * 2)
        ),
        defaultStart             : () => getDefaultStart(),
        defaultEnd               : () => getDefaultEnd(),
        isZoomUpAllowed          : (state, getters) => getters.duration > MIN_DURATION,
        isZoomDownAllowed        : (state, getters) => getters.duration < MAX_DURATION,
        hasArchive               : (state) => !!state.archiveStats && !!state.archiveStats.period,
        downloadPeriod           : (state, getters) => {
            let start = (state.downloadLimitsPX.left * getters.secondsInPx * 1000) + state.period.start;
            let end   = (state.downloadLimitsPX.right * getters.secondsInPx * 1000) + state.period.start;

            start = start < Date.now() ? start : Date.now();
            end   = end < Date.now() ? end : Date.now();

            return { start, end };
        },
        getPXFromSeconds         : (state, getters) => (seconds) => seconds / getters.secondsInPx,
        getSecondsFromPX         : (state, getters) => (px) => px * getters.secondsInPx,
        firstArchiveAvailableDate: (state) => (
            state.archiveStats && state.archiveStats.period ? state.archiveStats.period.startTime : false
        ),

        currentDate: (state) => (lang) => (
            state.playingTime ? formatDate(state.playingTime, 'HH:mm:ss, MMMM D') : null
        ),

        intervals      : (state, getters) => {
            const startSeconds  = convertDateToSeconds(state.period.start);
            const endSeconds    = convertDateToSeconds(state.period.end);
            const clipShift     = startSeconds;
            const intervalsTemp = [];
            const ranges        = state.ranges
                ? state.ranges
                : getDefaultPlaylist({
                    start: state.period.start,
                    end  : state.period.end,
                }).ranges;

            ranges.forEach((range, index, arr) => {
                const rangeStart   = convertDateToSeconds(range.date);
                const rangeEnd     = arr[index + 1] ? convertDateToSeconds(arr[index + 1].date) : endSeconds;
                const intervalType = getters.getIntervalType({
                    status     : range.status,
                    isLastRange: index === arr.length - 1,
                    date       : range.date,
                });

                intervalsTemp.push({
                    width: getters.getPXFromSeconds(rangeEnd - startSeconds)
                        - getters.getPXFromSeconds(rangeStart - startSeconds),
                    left : getters.getPXFromSeconds(rangeStart - startSeconds),
                    type : intervalType,
                    start: rangeStart - clipShift,
                    end  : rangeEnd - clipShift,
                });
            });
            return intervalsTemp;
        },
        playlistRanges : (state) => {
            const ranges = [];
            let hasEnd   = false;

            if (state.playlist && state.playlist.period) {
                state.ranges.some((range, index) => {
                    const rangeDateMS = dayjs(new Date(range.date)).valueOf();

                    if (rangeDateMS >= state.playlist.period.start
                        && rangeDateMS < state.playlist.period.end
                        && range.status === 0) {
                        const startTime = dayjs(new Date(range.date)).valueOf();
                        let endTime;

                        if (state.ranges[index + 1]
                            && dayjs(new Date(state.ranges[index + 1].date)).valueOf() < state.playlist.period.end) {
                            endTime = dayjs(new Date(state.ranges[index + 1].date)).valueOf();
                        }
                        else {
                            endTime = state.playlist.period.end;
                            hasEnd  = true;
                        }

                        if (endTime - startTime > 100) {
                            ranges.push({
                                start : startTime,
                                end   : endTime,
                                status: range.status,
                            });
                        }
                    }

                    if (!state.ranges[index + 1]
                        || dayjs(new Date(state.ranges[index + 1].date)).valueOf() >= state.playlist.period.end) {
                        hasEnd = true;
                    }

                    return hasEnd;
                });
            }

            return ranges;
        },
        getIntervalType: (state) => ({ status, isLastRange, date }) => {
            const liveTime   = state.archiveStats && state.archiveStats.period
                ? state.archiveStats.period.endTime : dayjs(new Date()).toISOString();
            let intervalType = RANGE_TYPES.GAP;

            if (isLastRange && liveTime <= date && !status) {
                intervalType = RANGE_TYPES.LIVE;
            }
            return status ? RANGE_TYPES.FRAGMENT : intervalType;
        },
    },

    actions: {
        /* bring */
        zoomUp ({ commit, state, getters, dispatch }, { streamUid, streamId }) {
            const currentTime = dayjs(new Date()).valueOf();
            const playingTime = state.playingTime || currentTime;

            if (getters.isZoomUpAllowed) {
                commit('setPeriod', {
                    period: {
                        start: state.period.start + getters.getStartModifier(playingTime),
                        end  : state.period.end - getters.getEndModifier(playingTime),
                    },
                });
                dispatch('createArchiveDataWatcher', {
                    streamId,
                    rangesUrl      : getRangesUrl(streamUid),
                    isArchiveEnable: true,
                });
            }
        },
        /* put away */
        zoomDown ({ commit, state, getters, dispatch }, { streamUid, streamId }) {
            const currentTime = dayjs(new Date()).valueOf();
            const playingTime = state.playingTime || currentTime;

            if (getters.isZoomDownAllowed) {
                commit('setPeriod', {
                    period: {
                        start: state.period.start - getters.getStartModifier(playingTime),
                        end  : state.period.end >= currentTime + (3 * HOUR_IN_MS)
                            ? state.period.end : state.period.end + getters.getEndModifier(playingTime),
                    },
                });
                dispatch('fetchArchiveData', { rangesUrl: getRangesUrl(streamUid), isArchiveEnable: true, streamId });
                dispatch('createArchiveDataWatcher', {
                    streamId,
                    rangesUrl      : getRangesUrl(streamUid),
                    isArchiveEnable: true,
                });
            }
        },
        moveLeft ({ commit, state, getters, dispatch }, { streamUid, streamId }) {
            commit('setPeriod', {
                period: {
                    start: state.period.start - getters.periodModifier,
                    end  : state.period.end - getters.periodModifier,
                },
            });
            dispatch('fetchArchiveData', { rangesUrl: getRangesUrl(streamUid), isArchiveEnable: true, streamId });
        },
        moveRight ({ commit, state, getters, dispatch }, { streamUid, streamId }) {
            commit('setPeriod', {
                period: {
                    start: state.period.start + getters.periodModifier,
                    end  : state.period.end + getters.periodModifier,
                },
            });
            dispatch('fetchArchiveData', { rangesUrl: getRangesUrl(streamUid), isArchiveEnable: true, streamId });
        },
        toCenter ({ commit, state, getters, dispatch }, { streamUid, streamId }) {
            if (state.playerViewMode === PLAYER_VIEW_MODES.LIVE && !state.playlist) {
                return;
            }
            commit('setPeriod', {
                period: {
                    start: state.playingTime - (getters.duration / 2),
                    end  : state.playingTime + (getters.duration / 2),
                },
            });
            dispatch('fetchArchiveData', { rangesUrl: getRangesUrl(streamUid), isArchiveEnable: true, streamId });
        },
        updatePeriodAfterDrag ({ commit, dispatch, state }, { period }) {
            commit('setPeriod', { period });
        },

        updateRanges ({ commit }, { ranges }) {
            function getSplitLastRange () {
                const lastRange = ranges && ranges[ranges.length - 1]
                    ? JSON.parse(JSON.stringify(ranges[ranges.length - 1])) : null;

                if (lastRange && !lastRange.status && dayjs(lastRange.date).valueOf() < Date.now()) {
                    lastRange.date = dayjs().toISOString();
                    return lastRange;
                }
                return null;
            }

            const liveRange = getSplitLastRange();

            if (liveRange) {
                ranges.push(liveRange);
            }

            commit('setRanges', { ranges });
        },
        updatePlayingTime ({ commit }, { time }) {
            commit('setPlayingTime', { time });
        },
        updatePlayerViewMode ({ commit, dispatch }, { mode }) {
            commit('setPlayerViewMode', { mode });

            if (mode === PLAYER_VIEW_MODES.LIVE) {
                dispatch('updateSpeedIndex', { speedIndex: 1 });
            }
        },
        updatePlaylist ({ commit, state }, { url, period, mode }) {
            if (mode === PLAYER_VIEW_MODES.ARCHIVE) {
                const startTime = dayjs(new Date(period.start)).toISOString();
                const endTime   = dayjs(new Date(period.end)).toISOString();

                commit('setPlaylist', {
                    playlist: {
                        period,
                        url: `${url}?start_time=${startTime}&end_time=${endTime}`,
                    },
                });
            }
            else {
                commit('setPlaylist', { playlist: { url } });
            }
        },
        updatePeriod ({ commit }, { period }) {
            commit('setPeriod', { period });
        },
        resetPeriod ({ dispatch, getters }) {
            dispatch('updatePeriod', {
                period: {
                    start: getters.defaultStart,
                    end  : getters.defaultEnd,
                },
            });
        },
        updateProgressBarWidth ({ commit }, { id }) {
            setTimeout(() => {
                const progressBarElem = document.getElementById(id);
                commit('setProgressBarWidth', {
                    progressBarWidth: progressBarElem ? progressBarElem.getBoundingClientRect().width : 0,
                });
            }, 100);
        },

        fetchArchive (
            { commit, dispatch, getters, state },
            { startTime, endTime, streamUid, streamId, isArchiveEnable },
        ) {
            if (isArchiveEnable) {
                commit('setFetching', { isFetchingArchive: true });

                httpClient
                    .get(getRangesUrl(streamUid), {
                        params: {
                            start_time: startTime,
                            end_time  : endTime,
                        },
                    })
                    .then((response) => {
                        if (response.data.period) {
                            dispatch('updatePeriod', {
                                period: {
                                    start: dayjs(new Date(response.data.period.startTime)).valueOf(),
                                    end  : dayjs(new Date(response.data.period.endTime)).valueOf(),
                                },
                            });
                            dispatch('updateRanges', { ranges: response.data.ranges });
                            dispatch('fetchAnnotations', { streamUid, isArchiveEnable, streamId });
                            dispatch('fetchArchiveStats', { streamUid, isArchiveEnable });
                        }
                    })
                    .finally(() => {
                        commit('setFetching', { isFetchingArchive: false });
                    });
            }
        },
        fetchArchiveRanges ({ commit, dispatch, getters, state }, { rangesUrl, isArchiveEnable }) {
            if (isArchiveEnable) {
                commit('setFetchingArchiveRanges', { isFetchingArchiveRanges: true });

                httpClient
                    .get(rangesUrl, {
                        params     : {
                            start_time: getters.periodISO.start,
                            end_time  : getters.periodISO.end,
                        },
                        cancelToken: new CancelToken((cancel) => {
                            state.cancelArchiveRangesRequest = cancel;
                        }),
                    })
                    .then((response) => {
                        if (response.data.period) {
                            dispatch('updateRanges', { ranges: response.data.ranges });
                        }
                        state.cancelArchiveRangesRequest = null;
                    })
                    .finally(() => {
                        commit('setFetchingArchiveRanges', { isFetchingArchiveRanges: false });
                    });
            }
        },
        fetchArchiveStats ({ commit, dispatch }, { streamUid, isArchiveEnable }) {
            if (isArchiveEnable) {
                const url = getArchiveStatsUrl(streamUid);

                httpClient
                    .get(url)
                    .then((res) => {
                        if (res.data.period) {
                            commit('setArchiveStats', { stats: res.data });
                            dispatch(
                                'fetchAvailableDays',
                                {
                                    startDay: res.data.period.startTime,
                                    endDay  : res.data.period.endTime,
                                    streamUid,
                                    isArchiveEnable,
                                },
                            );
                        }
                    });
            }
        },
        fetchAvailableDays ({ commit, dispatch, getters, state }, { startDay, endDay, streamUid, isArchiveEnable }) {
            if (isArchiveEnable) {
                const url = getAvailableDaysUrl(streamUid);

                httpClient
                    .get(url, {
                        params: {
                            start_time: startDay,
                            end_time  : endDay,
                        },
                    })
                    .then((res) => {
                        commit('setAvailableDays', { availableDays: res.data });
                    });
            }
        },
        fetchArchiveData ({ dispatch }, { rangesUrl, isArchiveEnable, streamId }) {
            dispatch('fetchArchiveRanges', { rangesUrl, isArchiveEnable, streamId });
            dispatch('fetchAnnotations', { isArchiveEnable, streamId });
        },
        fetchAnnotations ({ getters, dispatch, commit }, { isArchiveEnable, streamId }) {
            if (isArchiveEnable) {
                commit('setFetchingAnnotations', { isFetchingAnnotations: true });

                httpClient
                    .get(ANNOTATIONS_URL, {
                        params: {
                            streamId,
                            from: getters.periodISO.start,
                            to  : getters.periodISO.end,
                        },
                    })
                    .then((res) => {
                        dispatch('updateAnnotations', { annotations: res.data });
                    })
                    .finally(() => {
                        commit('setFetchingAnnotations', { isFetchingAnnotations: false });
                    });
            }
        },
        fetchDownloadArchiveUrl ({ commit, getters }, { streamUid }) {
            return httpClient
                .get(getDownloadArchiveUrl({ streamUid }), {
                    params: {
                        start_time     : dayjs(getters.downloadPeriod.start).toISOString(),
                        end_time       : dayjs(getters.downloadPeriod.end).toISOString(),
                        timezone_offset: dayjs().utcOffset(),
                    },
                })
                .then((res) => res.data);
        },

        updateAnnotations ({ commit }, { annotations }) {
            commit('setAnnotations', { annotations });
        },
        resetArchiveData ({ commit, dispatch }) {
            commit('setArchiveStats', { stats: null });
            commit('setAvailableDays', { availableDays: [] });
            commit('setPeriod', { period: getDefaultPeriod() });
            commit('setProgressBarWidth', { progressBarWidth: 0 });
            commit('setPlaylist', { playlist: null });
            commit('setRanges', { ranges: [] });
            commit('setAnnotations', { annotations: [] });
            dispatch('updatePlayerViewMode', { mode: PLAYER_VIEW_MODES.LIVE });
        },
        createArchiveDataWatcher ({ commit, dispatch, getters }, { rangesUrl, isArchiveEnable, streamId }) {
            dispatch('clearArchiveDataWatcher');

            function getTime (secondsInPx) {
                return secondsInPx * 6 * 1000 > 3500 ? secondsInPx * 6 * 1000 : 3500;
            }

            const time = getTime(getters.secondsInPx);

            let timer = setTimeout(function tick () {
                dispatch('fetchArchiveData', { rangesUrl, isArchiveEnable, streamId });

                timer = setTimeout(tick, time);
                commit('setArchiveDataWatcherTimer', { timer });
            }, time);

            commit('setArchiveDataWatcherTimer', { timer });
        },
        clearArchiveDataWatcher ({ state }) {
            clearTimeout(state.archiveDataWatcherTimer);
        },

        /* for Player */
        updateSpeedIndex ({ commit }, { speedIndex }) {
            commit('setSpeedIndex', { speedIndex });
        },
        goToLive ({ dispatch, getters }, { isSeek, rangesUrl, liveUrl, isAvailableStream, isArchiveEnable, streamId }) {
            dispatch('updatePlayerViewMode', { mode: PLAYER_VIEW_MODES.LIVE });

            if (isAvailableStream) {
                if (!isSeek) {
                    dispatch('updatePeriod', {
                        period: {
                            start: dayjs(new Date(getters.defaultStart)).valueOf(),
                            end  : dayjs(new Date(getters.defaultEnd)).valueOf(),
                        },
                    });
                    dispatch('fetchArchiveData', { rangesUrl, isArchiveEnable, streamId });
                    dispatch('createArchiveDataWatcher', { rangesUrl, streamId });
                }
                dispatch('updatePlaylist', {
                    url   : liveUrl,
                    mode  : PLAYER_VIEW_MODES.LIVE,
                    period: null,
                });
            }
            else {
                dispatch('goToEmptyState');
            }
        },
        goToArchive ({ dispatch }, { period, playlistUrl }) {
            dispatch('updatePlaylist', {
                period,
                url : playlistUrl,
                mode: PLAYER_VIEW_MODES.ARCHIVE,
            });
            dispatch('updatePlayerViewMode', { mode: PLAYER_VIEW_MODES.ARCHIVE });
        },
        goToEmptyState ({ commit, dispatch }) {
            commit('setPlaylist', { playlist: null });
            dispatch('updatePlayingTime', { time: 0 });
        },
        onSeekHandler ({ state, getters, dispatch }, {
            intervalElem,
            clientX,
            rangesUrl,
            liveUrl,
            playlistUrl,
            isAvailableStream,
            progressBar,
        }) {
            function isLastElem (elem) {
                return getters.intervals.length === Number(elem.dataset.index) + 1;
            }

            function getThumbLeftPos () {
                const progressElemLeft = progressBar.getBoundingClientRect().left;
                return clientX - progressElemLeft;
            }

            function getSecondsFromPos () {
                const pointLeft = getThumbLeftPos(clientX);
                return getters.getSecondsFromPX(pointLeft);
            }

            function getTimeShift (currentTime, ranges) {
                let timeShift = 0;
                ranges.forEach((range) => {
                    if (range.status === 0 && currentTime >= range.end) {
                        timeShift += range.end - range.start;
                    }
                });
                return timeShift;
            }

            if (intervalElem.dataset.type === RANGE_TYPES.LIVE) {
                dispatch('goToLive', { isSeek: true, rangesUrl, liveUrl, isAvailableStream });
                dispatch('updateThumbPos', {
                    thumbPos: getters.getPXFromSeconds((Date.now() - state.period.start) / 1000),
                });
            }
            else if (intervalElem.dataset.type === RANGE_TYPES.GAP && isLastElem(intervalElem)) {
                return false;
            }
            else {
                let newCurrentSeconds = 0;

                if (intervalElem.dataset.type === RANGE_TYPES.GAP) {
                    newCurrentSeconds = getters.intervals[Number(intervalElem.dataset.index) + 1].start;
                }
                else if (intervalElem.dataset.type === RANGE_TYPES.FRAGMENT) {
                    newCurrentSeconds = getSecondsFromPos(clientX);
                    dispatch('updateThumbPos', {
                        thumbPos: getThumbLeftPos(clientX),
                    });
                }

                dispatch('goToArchive', {
                    period: {
                        start: (newCurrentSeconds * 1000) + state.period.start,
                        end  : ((newCurrentSeconds + DEFAULT_PLAYLIST_DURATION) * 1000) + state.period.start,
                    },
                    playlistUrl,
                });
            }
            return true;
        },
        updateThumbPos ({ state, commit }, { thumbPos }) {
            if (Math.trunc(thumbPos) !== state.thumbPos) {
                commit('setThumbPos', { thumbPos: Math.trunc(thumbPos) });
            }
        },
    },
};
