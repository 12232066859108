/* eslint-disable import/no-cycle */
import Vue                        from 'vue';
import Router                     from 'vue-router';
import { destinationPageStorage } from './extends/sessionStorageAdapter';
import store                      from './store/store';
import PAGES                      from './constants/pages';
import metaForProgressLoader      from './constants/metaForProgressLoader';

Vue.use(Router);

const router = new Router({
    mode  : 'history',
    base  : process.env.BASE_URL,
    routes: [
        {
            path     : '/',
            name     : 'main',
            meta     : {
                title: 'Yucca',
            },
            redirect : { name: PAGES.ALL_STREAMS },
            component: () => import(/* webpackChunkName: "main" */ './pages/Auth/AuthPage.vue'),
        },
        {
            path     : '/auth',
            name     : PAGES.AUTH,
            component: () => import(/* webpackChunkName: "auth" */ './pages/Auth/AuthPage.vue'),
        },
        {
            path     : '/init',
            name     : PAGES.INIT,
            component: () => import(/* webpackChunkName: "init" */ './pages/Init/InitPage.vue'),
        },
        {
            path     : '/profile/me',
            name     : PAGES.PROFILE,
            props    : true,
            meta     : {
                ...metaForProgressLoader,
            },
            redirect : { name: PAGES.PROFILE_INFO },
            component: () => import(/* webpackChunkName: "profile" */ './pages/Profile/ProfilePage.vue'),
            children : [
                {
                    path     : 'info',
                    name     : PAGES.PROFILE_INFO,
                    props    : true,
                    component: () => import(/* webpackChunkName: "stream-info" */ './pages/Profile/ProfileView.vue'),
                },
                {
                    path     : 'edit',
                    name     : PAGES.PROFILE_EDIT,
                    props    : true,
                    component: () => import(
                        /* webpackChunkName: "stream-setting" */ './pages/Profile/ProfileEdit.vue'),
                },
                {
                    path     : 'tokens',
                    name     : PAGES.PROFILE_TOKEN,
                    props    : true,
                    component: () => import(
                        /* webpackChunkName: "stream-permission" */ './pages/Profile/ProfileTokens.vue'),
                },
            ],
        },
        {
            path     : '/user/add',
            name     : PAGES.USER_ADD,
            meta     : {
                ...metaForProgressLoader,
            },
            component: () => import(/* webpackChunkName: "user-add" */ './pages/User/UserAddPage.vue'),
        },
        {
            path     : '/user/:login',
            name     : PAGES.USER,
            props    : true,
            meta     : {
                ...metaForProgressLoader,
            },
            redirect : { name: PAGES.USER_INFO },
            component: () => import(/* webpackChunkName: "user" */ './pages/User/UserPage.vue'),
            children : [
                {
                    path     : 'info',
                    name     : PAGES.USER_INFO,
                    props    : true,
                    component: () => import(/* webpackChunkName: "stream-info" */ './pages/User/UserView.vue'),
                },
                {
                    path     : 'edit',
                    name     : PAGES.USER_EDIT,
                    props    : true,
                    component: () => import(
                        /* webpackChunkName: "stream-setting" */ './pages/User/UserEdit.vue'),
                },
                {
                    path     : 'permission',
                    name     : PAGES.USER_PERMISSION,
                    props    : true,
                    component: () => import(
                        /* webpackChunkName: "stream-permission" */ './pages/User/UserStreamPermissions.vue'),
                },
            ],
        },
        {
            path     : '/streams',
            name     : PAGES.ALL_STREAMS,
            meta     : {
                ...metaForProgressLoader,
            },
            component: () => import(/* webpackChunkName: "streams" */ './pages/Streams/StreamsPage.vue'),
        },
        {
            path     : '/stream/:uid/view',
            name     : PAGES.STREAM_VIEW,
            props    : true,
            meta     : {
                ...metaForProgressLoader,
            },
            component: () => import(/* webpackChunkName: "stream-view" */ './pages/StreamView/StreamPage.vue'),
        },
        {
            path     : '/stream/:uid',
            name     : PAGES.STREAM,
            props    : true,
            meta     : {
                ...metaForProgressLoader,
            },
            redirect : { name: PAGES.STREAM_INFO },
            component: () => import(/* webpackChunkName: "stream" */ './pages/StreamInfo/InfoStreamPage.vue'),
            children : [
                {
                    path     : 'info',
                    name     : PAGES.STREAM_INFO,
                    props    : true,
                    component: () => import(/* webpackChunkName: "stream-info" */ './pages/StreamInfo/StreamInfo.vue'),
                },
                {
                    path     : 'setting',
                    name     : PAGES.STREAM_SETTING,
                    props    : true,
                    component: () => import(
                        /* webpackChunkName: "stream-setting" */ './pages/StreamInfo/StreamSettings.vue'),
                },
                {
                    path     : 'permission',
                    name     : PAGES.STREAM_PERMISSION,
                    props    : true,
                    component: () => import(
                        /* webpackChunkName: "stream-permission" */ './pages/StreamInfo/StreamPermission.vue'),
                },
                {
                    path     : 'debug',
                    name     : PAGES.STREAM_DEBUG,
                    props    : true,
                    component: () => import(
                        /* webpackChunkName: "stream-debug" */ './pages/StreamInfo/StreamDebug.vue'),
                },
            ],
        },
        {
            path     : '/add-stream',
            name     : PAGES.ADD_STREAM,
            meta     : {
                ...metaForProgressLoader,
            },
            component: () => import(/* webpackChunkName: "add-stream" */ './pages/StreamInfo/AddingStreamPage.vue'),
        },
        {
            path     : '/administration',
            name     : PAGES.ADMIN,
            meta     : {
                ...metaForProgressLoader,
            },
            redirect : { name: PAGES.ADMIN_STATS },
            component: () => import(/* webpackChunkName: "admin" */ './pages/Admin/AdminPage.vue'),
            children : [
                {
                    path     : 'stats',
                    name     : PAGES.ADMIN_STATS,
                    component: () => import(/* webpackChunkName: "admin-stats" */ './pages/Admin/AdminStats.vue'),
                },
                {
                    path     : 'config',
                    name     : PAGES.ADMIN_CONFIG,
                    component: () => import(/* webpackChunkName: "admin-config" */ './pages/Admin/AdminConfig.vue'),
                },
                {
                    path     : 'users',
                    name     : PAGES.ADMIN_USERS,
                    component: () => import(/* webpackChunkName: "admin-users" */ './pages/Admin/AdminUsers.vue'),
                },
                {
                    path     : 'license',
                    name     : PAGES.LICENSE,
                    component: () => import(/* webpackChunkName: "license" */ './pages/License/LicensePage.vue'),
                },
                {
                    path     : 'server-debug',
                    name     : PAGES.SERVER_DEBUG,
                    component: () => import(/* webpackChunkName: "server-debug" */ './pages/Admin/AdminDebug.vue'),
                },
            ],
        },
        {
            path    : '/license',
            name    : 'old-license',
            redirect: { name: PAGES.LICENSE },
        },
        {
            path     : '/bookmarks',
            name     : PAGES.BOOKMARKS,
            meta     : {
                ...metaForProgressLoader,
            },
            component: () => import(/* webpackChunkName: "bookmarks" */ './pages/BookmarksPage.vue'),
        },
        {
            path     : '/help',
            name     : PAGES.HELP,
            meta     : {
                ...metaForProgressLoader,
            },
            component: () => import(/* webpackChunkName: "help" */ './pages/HelpPage.vue'),
        },
        {
            path     : '/*',
            name     : PAGES.NOT_FOUND,
            component: () => import(/* webpackChunkName: "not-found" */ './pages/NotFoundPage.vue'),
        },
    ],
});

router.beforeEach((to, from, next) => {
    const isPublicPage = to.name === PAGES.AUTH || to.name === PAGES.INIT;

    if (!destinationPageStorage.getItem() && !isPublicPage) {
        destinationPageStorage.setItem(to);
    }

    store.dispatch('checkPermission')
        .then(({ status, statusCode }) => {
            if (!status && !isPublicPage) {
                next({ name: statusCode === 423 ? PAGES.INIT : PAGES.AUTH });
            }
            else if ((statusCode === 423 && to.name === PAGES.INIT)
                || (statusCode === 401 && to.name === PAGES.AUTH)
                || (status && !isPublicPage)) {
                next();
            }
            else if (isPublicPage) {
                next({ name: PAGES.ALL_STREAMS });
            }
            else {
                next({ name: statusCode === 423 ? PAGES.INIT : PAGES.AUTH });
            }
        });
});

router.afterEach(() => {
    if (window.scrollY) {
        window.scroll(0, 0);
    }
});

export default router;
