/* eslint-disable  import/prefer-default-export */

const isExist = (value) => value !== null && value !== undefined;

class SessionStorageAdapter {
    constructor ({ itemName, isPrimitive }) {
        this.itemName    = itemName;
        this.isPrimitive = isPrimitive;
    }

    getCorrectValue = (value, isPrimitive, correctionFunc) => isPrimitive ? value : correctionFunc(value);

    getItem () {
        return sessionStorage.getItem(this.itemName)
            ? this.getCorrectValue(sessionStorage.getItem(this.itemName), this.isPrimitive, JSON.parse)
            : null;
    }

    setItem (value) {
        if (isExist(value)) {
            sessionStorage.setItem(this.itemName, this.getCorrectValue(value, this.isPrimitive, JSON.stringify));
        }
    }

    removeItem () {
        sessionStorage.removeItem(this.itemName);
    }
}

const destinationPageStorage = new SessionStorageAdapter({ itemName: 'destinationPage', isPrimitive: false });

export {
    destinationPageStorage,
};
