import dayjs from 'dayjs';

function getDefaultStart () {
    return dayjs(new Date()).subtract(20, 'hour').valueOf();
}

function getDefaultEnd () {
    return dayjs(new Date()).add(3, 'hour').valueOf();
}

function getDefaultPeriod () {
    return {
        start: getDefaultStart(),
        end  : getDefaultEnd(),
    };
}

function getDefaultDownloadLimitsPX () {
    return {
        left : 0,
        right: 0,
    };
}

function getDefaultPlaylist ({ start, end }) {
    return {
        period: { startTime: start, endTime: end },
        ranges: [{ date: start, status: 0 }],
        url   : null,
    };
}

export {
    getDefaultStart,
    getDefaultEnd,
    getDefaultPeriod,
    getDefaultDownloadLimitsPX,
    getDefaultPlaylist,
};
