/* eslint-disable no-param-reassign */
import { formatDate } from './lib';

export default {
    install (Vue, options) {
        Vue.prototype.$yccConfig = options.config;

        Vue.prototype.$setDocumentTitle = (titles = []) => {
            if (document) {
                if (titles.length > 0) {
                    document.title = `${titles.join(' ')} - ${options.config.appTitle || 'Yucca'}`;
                }
                else {
                    document.title = `${options.config.appTitle || 'Yucca'}`;
                }
            }
        };

        Vue.prototype.$appLogo = options.config.appLogo;

        Vue.$setLanguage = (lang) => {
            Vue.prototype.$yccConfig.language = lang;
        };

        Vue.prototype.$formatDate = (value, format) => {
            const lang = Vue.prototype.$yccConfig.language;
            return formatDate(value, format, lang);
        };
        Vue.filter('formatDate', (value, format) => {
            const lang = Vue.prototype.$yccConfig.language;
            return formatDate(value, format, lang);
        });
    },
};
